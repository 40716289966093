import { useStaticQuery, graphql } from "gatsby";

export default function useProjectStatuses() {
  return useStaticQuery(graphql`
    query ProjectStatuses {
      wp {
        projectStatuses {
          nodes {
            description
            name
            slug
            options {
              parent
              color
            }
          }
        }
      }
    }
  `).wp.projectStatuses.nodes;
}
