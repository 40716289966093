import React from "react";

import Article from "../components/Article";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import ModuleArea from "../components/ModuleArea/ModuleArea";
import SEO from "../components/seo";
import Timeline from "../components/Timeline";
import { getBreadcrumbsHidden } from "../hooks/header";
import { useHTMLProcessor } from "../hooks/htmlProcessor";
import { PageContextProvider } from "../hooks/pageContext";
import { RekaiTrack } from "../hooks/rekai";

export default function ProjectPageTemplate({ pageContext }) {
  const {
    project: {
      databaseId,
      title,
      featuredImage,
      modularityModules,
      content,
      // articleHeader: { preambleImage },
      displaySettings: { postSingleShowFeaturedImage } = {},
      modifiedGmt: modifiedDate,
      contentMedia,
      // dateGmt: publishedDate,
      projectCategories: { nodes: projectCategories } = {},
      projectStatuses,
    },
    isPreview,
  } = pageContext;
  // console.log(pageContext.project);
  // console.log(projectCategories);

  const activeProjectStatusSlug = projectStatuses?.nodes.length
    ? projectStatuses.nodes[0].slug
    : "";

  const categories = projectCategories.map((category) => ({
    label: category.name,
    color: category.options?.color,
    slug: category.slug,
  }));
  // console.log(categories);

  const { contentArea } = modularityModules;
  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  const isBreadcrumbsHidden = getBreadcrumbsHidden();

  return (
    <PageContextProvider
      value={{
        ...pageContext,
        postId: databaseId,
        postTypeName: "project",
      }}
    >
      <Layout>
        <SEO title={title} />
        {!isPreview && <RekaiTrack send={true} />}
        {!isBreadcrumbsHidden && <Breadcrumb />}
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
            // image: preambleImage,
          }}
          childPages={categories}
          featuredImage={postSingleShowFeaturedImage && featuredImage}
          // publishedDate={publishedDate}
          modifiedDate={modifiedDate}
          className="o-margin-top-large"
        >
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              <Timeline active={activeProjectStatusSlug} />
              {processedContent.content}
            </div>
          </div>
          <div className="o-grid-row">
            {contentArea && <ModuleArea area={contentArea} />}
          </div>
        </Article>
      </Layout>
    </PageContextProvider>
  );
}
